<template>
  <!-- <div>受け取り予定</div> -->
  <!-- <div>（コード、スポット、受け取り方法の説明）</div> -->
  <!-- <div>プロフィール</div> -->
  <!-- <div>クーポン</div> -->
  <!-- <div>友達招待</div> -->
  <v-container style="max-width: 600px; background-color: #f5f5f5" class="pa-0 pt-5">
    <div class="text-center">本日のお受け取り予定</div>
    <PickupDayCard :day="$dayjs().toDate()" />
    <div class="text-center">明日のお受け取り予定</div>
    <PickupDayCard :day="$dayjs().add(1, 'day').toDate()" />
    <br />
    <div class="text-center">注文履歴</div>
    <v-card class="my-4 mx-8">
      <v-list dense v-for="order in orders" v-bind:key="order._id">
        <OrderItem :order="order" class="px-2" />
        <v-divider></v-divider>
      </v-list>
      <v-btn block v-on:click="fetchOrders">more</v-btn>
    </v-card>
    <br />
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import PickupDayCard from './components/PickupDayCard.vue';
  import OrderItem from './components/OrderItem.vue';
  export default {
    name: 'CorporationOrderHistory',
    components: {
      PickupDayCard,
      OrderItem,
    },
    data: () => ({
      orderPage: 0,
      orders: [],
      loading: true,
    }),
    async created() {},
    mounted() {
      this.fetchOrders();
    },
    methods: {
      async fetchOrders() {
        try {
          let { data } = await Methods.GetOrdersByCorporation({
            user_id: this.$store.state.user.user_id,
            // user_id: '632975baf7307c037aee91a9', // for test
            sortField: 'createdAt',
            sortOrder: -1,
            page: this.orderPage,
            perPage: 5,
          });
          this.orders = [...this.orders, ...data.companyorders];
          this.orderPage += 1;
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>